












































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { SupplierEntityModel, SupplierQueryModel } from '@common-src/entity-model/supplier-entity';
import SupplierService from '@common-src/service/supplier';

@Component
export default class SupplierListComponent extends TableDialogFormComponent<SupplierEntityModel, SupplierQueryModel> {
    SupplierEntityModel = SupplierEntityModel;
    created() {
        this.initTable({
            service: SupplierService,
            queryModel: new SupplierQueryModel(),
            tableColumns: SupplierEntityModel.getTableColumns()
        });
        this.getList();
    }
}

